import _hasKeys from "./has-keys";
var exports = {};
var hasKeys = _hasKeys;
exports = extend;

function extend() {
  var target = {};

  for (var i = 0; i < arguments.length; i++) {
    var source = arguments[i];

    if (!hasKeys(source)) {
      continue;
    }

    for (var key in source) {
      if (source.hasOwnProperty(key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
}

export default exports;